import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import banner from '../../assets/images/mv_01/mv_01.png'
import topImage from '../../assets/images/top_img/top_img.png'
import dotLeft from '../../assets/images/dot/dot_01.svg'
import dotRight from '../../assets/images/dot/dot_02.svg'
import featureImage from '../../assets/images/three-point/three-point.png'
import iconBook from '../../assets/images/icon-book/icon-book.svg'
import './index.scss'
import ButtonDefault from '../../components/ButtonDefault';
import Features from '../../components/Features';
import { useAppSelector } from '../../hooks';
import { Divider } from 'antd';
import iconBaby from '../../assets/images/icons/icon-baby.png';
import iconBirthday from '../../assets/images/icons/icon-birthday.png';
import iconGraduation from '../../assets/images/icons/icon-graduation.png';
import iconEntrance from '../../assets/images/icons/icon-entrance.png';
import iconChristmas from '../../assets/images/icons/icon-christmas.png';
import iconGift from '../../assets/images/icons/icon-gift.png';
import iconGiftBlack from '../../assets/images/gift-card/gift-card-black.svg';

const bannerStyle = {
  backgroundImage: `url(${banner})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: "100%",
  height: "300px",
  zIndex: '0'
}

const Home = () => {
  const dataPage = useAppSelector((state) => state.pageData);
  const [showReviewList, setShowReviewList] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowReviewList(document.getElementsByClassName("yotpo-reviews-carousel")[0].innerText !== '')
    }, 5000)
  }, []);

  return (
    <div className="main">
      <div className='banner' style={bannerStyle}></div>
      <div className='book-description'>
        <div className='content'>“<span style={{ color: '#00A5D9' }}>じぶんえほん</span>”は<p>日本の昔話を世界で唯一の絵本にして贈ることができるギフトサービスです</p></div>
      </div>
      <div className='img-book'>
        <img src={topImage} alt='top_image_じぶんえほん' />
      </div>
      <div className='book-create__feature'>
        <div className='img'>
          <img src={featureImage} alt='feature_image_じぶんえほん' />
        </div>
        <div className='rectangle'></div>
        <div className='book-create__step'>
          <div className='step flex'>
            <div className='step-number'><span>1</span></div>
            <div className='step-content'><p>自分の<span style={{ color: '#00A5D9' }}>名前</span>をつけられる</p></div>
          </div>
          <div className='step flex'>
            <div className='step-number'><span>2</span></div>
            <div className='step-content'><p>絵本の主人公を<span style={{ color: '#00A5D9' }}>カスタマイズ</span></p></div>
          </div>
          <div className='step flex'>
            <div className='step-number'><span>3</span></div>
            <div className='step-content'><p>自分だけの<span style={{ color: '#00A5D9' }}>パーソナライズ</span>絵本</p></div>
          </div>
        </div>
      </div>
      <div className='introduce-book'>
        <p>子供に昔話の絵本を読んだ後、子供がその主人公になりきって、ごっこ遊びをしませんでしたか？そんな子供たちの願いを叶えたいと思い、JibunEHONを作りました。</p>
        <p>自分の名前、自分に近い容姿または自分でカスタマイズした主人公が、絵本に登場したらどうでしょう。子供たちが喜ぶ顔が目に浮かびます。</p>
        <p>そんな自分だけのパーソナライズ絵本を、お子さんやお子さんの居る大切な方へ贈りませんか？</p>
      </div>
      <div className="yotpo yotpo-reviews-carousel"
        data-header-customisation-enabled="1"
        data-header-customisation-color="#56575f"
        data-header-customisation-font-size="18"
        data-header-customisation-text="ご購入者様の声"
        data-header-customisation-alignment="left"
        data-background-color="transparent"
        data-mode="most_recent"
        data-type="both"
        data-count="5"
        data-show-bottomline="1"
        data-autoplay-enabled="1"
        data-autoplay-speed="3000"
        data-show-navigation="1">
      </div>
      {
        showReviewList && (
          <div className='review-list' onClick={() => { window.location.href = '/static_pages/seo_review' }}>
            <span>レビュー一覧</span>
          </div>
        )
      }

      <div className='book-list'>
        <div className='book-list__background'></div>
        <div className='dot-background dot-left'>
          <img src={dotLeft} alt='dot-left_じぶんえほん' />
        </div>
        <div className='dot-background dot-right'>
          <img src={dotRight} alt='dot-right_じぶんえほん' />
        </div>
        <div className='book-list__description' style={{ opacity: 1 }}>
          <div className='title'>絵本と電子ギフト券紹介</div>
          <div className='book-list__slider'>
            {dataPage.data?.data?.books.map((item) => {
              return (
                <div className='book-item slider-background'>
                  <div className='title-slider'>{item.name}</div>
                  <div className='subtitle-slider'>{ item.id === 1 ? '0歳〜10歳におすすめ' : '3歳〜12歳におすすめ'}</div>
                  <div className="image-slider">
                    <img src={`${item.image_preview}`} key={item.id} alt={`${item.name}_じぶんえほん`} />
                  </div>
                  <ButtonDefault
                    classButton="full-xl book-button"
                    outline={false}
                    onClick={() => { window.location.href = `/books/${item.id}` }}
                    htmlType="button"
                  >
                    <img src={iconBook} alt='icon-book_じぶんえほん' style={{ margin: '0 5px 4px 0' }} />
                    絵本詳細はこちら
                  </ButtonDefault>
                </div>
              );
            })}

            <div className='book-item slider-background-gift'>
              <div className='title-slider'>電子ギフト券</div>
              <div className='subtitle-slider'>SNS等で絵本を贈れます</div>
              <ButtonDefault
                classButton="full-xl"
                outline={false}
                onClick={() => { window.location.href = '/static_pages/gift_usage' }}
                htmlType="button"
              >
                <img src={iconGiftBlack} alt='icon-gift_じぶんえほん' style={{ margin: '0 5px 4px 0' }} />
                電子ギフト券詳細はこちら
              </ButtonDefault>
            </div>
          </div>
        </div>
        <div className='features-jibunehon'>
          <Divider plain>絵本簡単作成手順</Divider>
          <p className='step-title'>手順1</p>
          <p className='step-content'>名前等の入力</p>
          <p style = {{textAlign: 'center',marginBottom: 0 }}>↓</p>
          <p className='step-title'>手順2</p>
          <p className='step-content'>主人公の容姿を選択</p>
          <p style = {{textAlign: 'center',marginBottom: 0 }}>↓</p>
          <p className='step-title'>手順3</p>
          <p className='step-content'>写真の選択（無しも可）</p>
          <p style = {{textAlign: 'center',marginBottom: 0 }}>↓</p>
          <p className='step-title'>手順4</p>
          <p className='step-content'>メッセージの入力</p>
          <p style = {{textAlign: 'center',marginBottom: 0 }}></p>
        </div>
        <Features folder={'homepage'}/>
        <div className='features-jibunehon'>
        <Divider plain>こんな時に絵本を贈ろう</Divider>
          <div className="icon-container">
            <div className="icon-item">
              <img src={iconBaby} alt="出産祝い_じぶんえほん" />
              <p>出産祝い／記念に</p>
            </div>
            <div className="icon-item">
              <img src={iconBirthday} alt="誕生日に_じぶんえほん" />
              <p>誕生日に</p>
            </div>
            <div className="graduation-icon-container">
              <img src={iconGraduation} alt="卒園／卒業記念に_じぶんえほん" />
              <p>卒園／卒業記念に</p>
            </div>
            <div className="icon-item">
              <img src={iconEntrance} alt="入園／入学記念に_じぶんえほん" />
              <p>入園／入学記念に</p>
            </div>
            <div className="christmas-icon-container">
              <img src={iconChristmas} alt="クリスマスプレゼントに_じぶんえほん" />
              <p>クリスマスプレゼントに</p>
            </div>
            <div className="icon-item">
              <img src={iconGift} alt="お祝い返しや各種お礼に_じぶんえほん" />
              <p>お祝い返しや各種お礼に</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Home;
