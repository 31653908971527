import React from 'react';
import logoFooter from '../../assets/images/footer-logo/footer-logo.svg'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {

  const rejectToPage = (url: string) => {
    window.location.href = url
  }

  return (
    <div className="footer-default">
      <div className="footer-layout" style={{ height: '192px' }}>
        <div className="footer-top" style={{ backgroundColor: '#F8F9FA' }}>
          <div className="logo" onClick={() => rejectToPage('/')}>
            <img src={logoFooter} alt="logo" />
          </div>
          <div className="footer-content">
            <div>
              <span onClick={() => rejectToPage('/static_pages/terms_of_use')}>利用規約</span>
              <span style={{ marginLeft: '32px' }} onClick={() => rejectToPage('/static_pages/privacy_policy')}>プライバシーポリシー</span>
            </div>
            <div>
              <span onClick={() => rejectToPage('/static_pages/act_on_specified_commercial_transactions')}>特定商取引法に基づく表示</span>
              <span style={{ marginLeft: '32px' }} onClick={() => rejectToPage('/static_pages/contact')}>お問い合わせ</span>
            </div>
            <div>
              <a href="https://www.instagram.com/jibun_ehon/" target="_blank"><FontAwesomeIcon icon={faSquareInstagram} className="icon" /></a>
              <a href="https://www.facebook.com/people/Jibunehon/100094274657636/?locale=ja_JP" target="_blank"><FontAwesomeIcon icon={faFacebook} className="icon" /></a>
              <a href="https://x.com/JibunEHON" target="_blank"><FontAwesomeIcon icon={faSquareXTwitter} className="icon" /></a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">©JibunEHON</div>
        { window.location.pathname === '/' ? <div className="footer-bottom-homepage">R2 事業再構築</div> : ''}
      </div>
    </div>
  );
}

export default Footer;
