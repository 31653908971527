import React, { useState, useEffect } from "react";
import "./index.scss";
import { Popover, Divider } from "antd";
import sentiment from "../../../assets/images/sentiment/sentiment.svg";
import giftCard from "../../../assets/images/gift-card/gift-card.svg";
import Carousel from "react-material-ui-carousel";
import ButtonDefault from "../../../components/ButtonDefault";
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import iconNext from "../../../assets/images/arrow-book-detail/arrow-right.svg";
import iconPrev from "../../../assets/images/arrow-book-detail/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDataPage } from "../../../redux/slices/pageDataSlice";
import ReactHtmlParser from "react-html-parser";
import Breadcrumb from "../../../components/Breadcrumb";
import Features from "../../../components/Features";
import { routesObj } from "../../../routes/routes";
import { utils } from "../../../utils/utils";
import { MethodOption } from "../../../constants/methodOption";
import axios from "axios";
import { designNon, shippingFee } from "../../../../configuration";
import AutoPlayVideo from "../../../components/AutoPlayVideo";
import iconBaby from "../../../assets/images/icons/icon-baby.png";
import iconBirthday from "../../../assets/images/icons/icon-birthday.png";
import iconGraduation from "../../../assets/images/icons/icon-graduation.png";
import iconEntrance from "../../../assets/images/icons/icon-entrance.png";
import iconChristmas from "../../../assets/images/icons/icon-christmas.png";
import iconGift from "../../../assets/images/icons/icon-gift.png";

const Details = (data) => {
  const dataPage = useAppSelector((state) => state.pageData);
  const itemsCart = useAppSelector((state) => state.cart);
  const book = dataPage?.data?.data?.book || { images: [] };
  const pdf = dataPage?.data?.data?.pdf || "";
  const otherBook = dataPage?.data?.data?.other_book || {};
  const [hovered, setHovered] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [hoveredCharacter, setHoveredCharacter] = useState<boolean>(false);
  const [loadingGift, setLoadingGift] = useState<boolean>(false);
  const [loadingCharacter, setLoadingCharacter] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<String>();
  const dispatch = useAppDispatch();
  const currentUser = JSON.parse(utils.getStorage("current_user"))
  useEffect(() => {
    dispatch(getDataPage(data));
  }, []);

  const addTitleMetaTag = (bookName: string, site: string) => {
    document.querySelector(`meta[property="${site}:title"]`)?.remove()
    document.title = `伝える想いをオリジナル絵本ギフトで JibunEHON（じぶんえほん） | ${bookName}`;
    let titleMetaTag = document.createElement('meta');
    titleMetaTag.setAttribute("content", `伝える想いをオリジナル絵本ギフトで JibunEHON（じぶんえほん） | ${bookName}`);
    titleMetaTag.setAttribute("property", `${site}:title`);
    document.getElementsByTagName('head')[0].insertBefore(titleMetaTag, document.getElementsByTagName('meta')[0]);
  }

  useEffect(() => {
    addTitleMetaTag(book.name, 'og')
    addTitleMetaTag(book.name, 'twitter')
    if (book.id === 1) {
      setCurrentSlide('男の子か女の子かでタイトルが変わり、お子さんやお孫さんの名前を挿入できます。\n主人公の容姿をカスタマイズできます。')
    } else {
      setCurrentSlide('お子さんやお孫さんの名前を挿入でき、主人公の容姿をカスタマイズできます。')
    }
  }, [book.name]);

  // 受注日から土日祝日を除いた4日後の最短発送日を算出
  const BUSINESS_DAYS = 4;
  const displayStartDate = utils.getBusinessDate(new Date(), BUSINESS_DAYS).toISOString().slice(0, 10)

  const formatter = new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
  });

  const handleMakeCharacter = () => {
    setDisabled(true);
    if (JSON.parse(utils.getStorage("items"))?.selectedBook?.length > 0) {
      utils.setStorage('items', JSON.stringify(itemsCart))
    }
    if (
      JSON.parse(utils.getStorage("items"))?.selectedBook?.length > 0 &&
      itemsCart.selectedBook?.length > 0 &&
      JSON.parse(utils.getStorage("items"))?.selectedBook[0]?.is_gift &&
      itemsCart?.selectedBook[0]?.is_gift
    ) {
      setHoveredCharacter(true);
    } else {
      setLoadingCharacter(true);
      window.location.href = routesObj.character.path.replace(':id', book.id);
    }
    setDisabled(false);
  };
  const backToIndex = () => {
    window.location.href = '/'
  };

  return (
    <>
      <Breadcrumb backButton={true} step={true} handleStep={backToIndex} />
      <div className="book_details">
        <h1 className="book_name">{book.name}</h1>
        <div className="yotpo bottomLine m-20"
          data-appkey="GwqMBZgb9JxR5K1UEB3YvZLEDMaDo3fre4dTWvfs"
          data-product-id={ book.id }
          data-name={ book.name }
          data-url={ window.location.href }
          data-image-url={ book.images[0]?.url?.split('?')[0] }>
        </div>
        <Carousel
          onChange={(active) => setCurrentSlide(book.images[active+1].description)}
          className="book_slider"
          autoPlay={false}
          swipe={false}
          animation="fade"
          indicators={true}
          cycleNavigation={true}
          navButtonsAlwaysVisible
          indicatorContainerProps={{
            style: {
              width: "auto",
              marginRight: "20px",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              width: "12px",
              height: "12px",
              border: "1px solid black",
              color: "#FFFFFF",
              margin: "0 8px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              width: "12px",
              height: "12px",
              border: "1px solid black",
              color: "#22304D",
              backgroundColor: "#22304D",
            },
          }}
          navButtonsProps={{
            style: {
              backgroundColor: "#fff",
              borderRadius: "6px",
              border: "1px solid",
              width: "42px",
              height: "42px",
              opacity: ".9",
              marginTop: "-10px",
            },
          }}
          navButtonsWrapperProps={{ style: { bottom: "0", top: "unset" } }}
          NextIcon={<img src={iconNext} alt="icon-next" />}
          PrevIcon={<img src={iconPrev} alt="icon-prev" />}
        >
          {book.images.slice(1, 6).map((image) => {
            return (
              <>
                <Grid item key={image}>
                  <div className="image_slider image_detail">
                    {image.type === 'video/mp4' ? ( // 動画ファイルの場合
                      <AutoPlayVideo src={image.url} />
                    ) : (
                      <img src={image.url} /> // 画像ファイルの場合
                    )}
                  </div>
                </Grid>
              </>
            );
          })}
        </Carousel>
        <div className="image_description">
          {currentSlide}
        </div>
        <div className="btn-pdf">
          <a href={pdf} target="_blank">
            <ButtonDefault
              classButton="button-pdf"
              outline
              htmlType="button"
              disabled={disabled}
            >
              絵本のサンプルを見る（PDF）
            </ButtonDefault>
          </a>
        </div>
        <div className="text_image style_border m-20">
          <img
            style={{ width: "24px", height: "24px", marginTop: "3px" }}
            src={sentiment}
            alt="sentiment"
          />
          <p style={{ width: "180px" }}>キャラクターを作る</p>
        </div>
        <div className="price_button m-20 box-add__character">
          <p>
            {formatter.format(book.price)} <span>(消費税､送料込)</span>
          </p>
          <div className="btn-make__character">
            <Popover
              content="絵本と電子ギフト券は 同時に購入できません。"
              open={hoveredCharacter}
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              className="full-small"
              onOpenChange={() => {
                if (hoveredCharacter) setHoveredCharacter(false);
              }}
              trigger="click"
            >
              <ButtonDefault
                classButton="full-small"
                outline={false}
                onClick={handleMakeCharacter}
                htmlType="button"
                loading={loadingCharacter}
                disabled={disabled}
              >
                キャラクターを作る
              </ButtonDefault>
            </Popover>
          </div>
        </div>
        <div className="text_image style_border m-20" style={{ marginBottom: "16px" }}>
          <img
            style={{ width: "24px", height: "24px", marginTop: "3px" }}
            src={giftCard}
            alt="giftcard"
          />
          <p style={{ minWidth: "240px" }}>電子ギフト券<span>SNS等で絵本を贈れます</span></p>
        </div>
        <div className="m-20 box-add__gift">
          <ButtonDefault
            classButton="full-small w-100"
            outline={false}
            onClick={() => { window.location.href = '/static_pages/gift_usage' }}
            disabled={disabled}
            htmlType="button"
            loading={loadingGift}
          >
            電子ギフト券詳細はこちら
          </ButtonDefault>
        </div>
      </div>
       <p className="m-20" style={{ marginTop: "20px", marginBottom: "0" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>製品仕様</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              ・本のサイズ: A4サイズ(210mm × 297mm) <br/>
              ・ページ数: 28ページ<br/>
              ・使用紙: YUPO(ユポ) <br/>
              ・印刷方法: デジタルオフセット(RGB印刷)<br/>
              ・製本方法: 無線綴製本<br/>
              ・付属品: 専用ケース
            </Typography>
          </AccordionDetails>
        </Accordion>
      </p>
      <div className="date-container">
        <p className="date_title m-20">最短発送日</p>
        <p className="date m-20">{displayStartDate}</p>
      </div>
      <p className="book_content m-20">{ReactHtmlParser(book.explanation)}</p>
      <p className="m-20">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>絵本のあらすじ</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{ReactHtmlParser(book.synopsis)}</Typography>
          </AccordionDetails>
        </Accordion>
      </p>
      <p className="book_content m-20">{ReactHtmlParser(book.main_points)}</p>
      <div className="book_illustrator">
        <p className="book_illustrator-title">イラストレーター</p>
        <p className="book_illustrator-name">{book.illustrator}</p>
        <div className="book_illustrator-info">
          <div>{ReactHtmlParser(book.illustrator_info)}</div>
        </div>
      </div>
      <div className={(currentUser !== null) ? 'can-write' : 'not-write'}>
        <div className="yotpo yotpo-main-widget"
          data-write-only="true"
          data-product-id={ book.id }
          data-price="5500"
          data-currency="JPY"
          data-name={ book.name }
          data-url={ window.location.href }
          data-image-url={ book.images[0]?.url?.split('?')[0] }>
        </div>
      </div>
      <Features folder={`book-${book.id}`}/>
      <div className='features-jibunehon'>
        <Divider plain>こんな時に絵本を贈ろう</Divider>
        <div className="icon-container">
          <div className="icon-item">
            <img src={iconBaby} alt="出産祝い" />
            <p>出産祝い／記念に</p>
          </div>
          <div className="icon-item">
            <img src={iconBirthday} alt="誕生日に" />
            <p>誕生日に</p>
          </div>
          <div className="graduation-icon-container">
            <img src={iconGraduation} alt="卒園／卒業記念に" />
            <p>卒園／卒業記念に</p>
          </div>
          <div className="icon-item">
            <img src={iconEntrance} alt="入園／入学記念に" />
            <p>入園／入学記念に</p>
          </div>
          <div className="christmas-icon-container">
            <img src={iconChristmas} alt="クリスマスプレゼントに" />
            <p>クリスマスプレゼントに</p>
          </div>
          <div className="icon-item">
            <img src={iconGift} alt="お祝い返しや各種お礼に" />
            <p>お祝い返しや各種お礼に</p>
          </div>
        </div>
      </div>
      <div onClick={() => { window.location.href = `/books/${otherBook.id}` }}>
        <a href={`/books/${otherBook.id}`}
          onClick={(e) => { e.preventDefault() }} >
          「{ otherBook.name }」はこちら→
        </a>
      </div>
      <div onClick={() => { window.location.href = '/static_pages/gift_usage' }}>
        <a href="/static_pages/gift_usage"
          onClick={(e) => { e.preventDefault() }} >
          「{ book.name }の電子ギフト券」はこちら→
        </a>
      </div>
    </>
  );
};

export default Details;
